import React, { useContext } from 'react';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { Lframe } from '@latitude/lframe';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import { SITE_URL, PRODUCT_NAME } from '@/utils/constants';
import { InstalmentInterestFreeCalculator } from '@/components/instalment-interest-free-calculator';
import GoMicrositeHeader from '../_go-microsite-header';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const IIFCalculator = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 120px !important;
          background-color: #f8f8f8;

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Metadata
          title="Interest Free Payment Plan Calculator | GO Mastercard"
          description="Calculate repayments for purchases using Equal Monthly payment plans"
          keywords={PRODUCT_NAME}
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <GoMicrositeHeader data={state.secondaryNavData} />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Box backgroundColor={COLOR.GREY6}>
            <div
              css={`
                position: relative;
                margin-top: 55px;
                @media (min-width: ${BREAKPOINT.LG}) {
                  margin-top: 80px;
                }
              `}
            >
              <Lframe
                css={`
                  background-image: url(${require('../images/lframe-calculator.png')
                    .default});
                  background-position: 25px 0;
                  z-index: auto;

                  @media (min-width: ${BREAKPOINT.MD}) {
                    background-position: 54vw 0;
                  }

                  @media (min-width: ${BREAKPOINT.LG}) {
                    background-size: 1250px auto;
                    background-position: 50vw 0;
                  }

                  @media (min-width: ${BREAKPOINT.XL}) {
                    background-position: 53vw 0;
                  }
                  @media (min-width: ${BREAKPOINT.LG}) {
                    background-size: 880px auto;
                  }
                `}
              />

              <Hero
                title="Interest Free payment plan calculator"
                text="Calculate repayments for purchases using Equal Monthly payment plans"
              />
            </div>
          </Box>
        )}

        <Box.Section
          backgroundColor={COLOR.GREY6}
          id="calculator"
          css={`
            @media (min-width: ${BREAKPOINT.MD}) {
              margin-top: 48px;
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              margin-top: -96px;
            }
            @media (min-width: ${BREAKPOINT.XL}) {
              margin-top: -40px;
            }
          `}
        >
          <InstalmentInterestFreeCalculator
            annualFee={0}
            establishmentFee={0}
            minimumAmount={490}
            noMinimumAmount={true}
            isGemVisaMac={true}
            noMinPurchaseText={true}
            helperTextForAmmount={
              'Minimum purchase amount applies. See current promotions for terms and conditions.'
            }
            monthlyFee={10.95}
            monthlyTerms={[24, 36, 48, 50, 60]}
            importantInfo={[
              'These payments are for Equal monthly payment plans only and do not take into account any other purchases or balances.',
              'The monthly, weekly and daily payments are approximate only.',
              "Monthly payments are required, exact amount specified in the customer's statement.",
              'Minimum financed amount applies. See current promotions for terms and conditions.',
              'Various plans only available during specific promotional periods.'
            ]}
          />
        </Box.Section>
      </main>
    </Layout>
  );
};

export default IIFCalculator;
